import classNames from "classnames"
import { useRef, useState } from "react"

export default function CopyToClipboard ({text, children, noLink, confirmBelow}) {

  text ||= ''

  const textareaRef = useRef(null)
  const [copied, setCopied] = useState(false)

  async function copyCaptionToClipboard (e) {
    e.preventDefault()

    let success = false

    // Use the modern clipboard API...
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text)
        success = true
      } catch (err) {
        console.warn('Failed to copy to clipboard with new API')
        console.error(err)
      }
    }

    // Fallback on the (deprecated) API for legacy browser...
    if (!success) {
      try {
        textareaRef.current.select()
        document.execCommand("copy")
        textareaRef.current.blur()
        success = true
      } catch (err) {
        console.warn('Failed to copy to clipboard with old API')
        console.error(err)
      }
    }

    setCopied(success)

    // Check for the textarea to be sure this component is still mounted
    setTimeout(() => { if (textareaRef.current) setCopied(false) }, 1500)
  }

  return <>
    {/* NOTE: Avoid `display:none` or `type=hidden` - those prevent copy in some browsers */}
    <textarea ref={textareaRef} defaultValue={text}/>
    <span className="copy-container">
      { noLink ?
        <div onClick={copyCaptionToClipboard}>
          {children}
        </div>
      :
        <a onClick={copyCaptionToClipboard} href={text.startsWith('http') ? text : ''}>
          {children}
        </a>
      }
      <span className="confirmation-container ">
        <span className={classNames("confirmation", {visible: copied, down: !!confirmBelow})}>
          &#10003; Copied
        </span>
      </span>
    </span>

    <style jsx>{`
      .copy-container {
        display: inline-block;
        position: relative;
      }
      .confirmation-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        pointer-events: none;
        display: block;
      }
      .confirmation {
        position: relative;
        top: -1rem;
        margin: auto;
        padding: 0.25rem 0.75rem;
        background-color: rgba(0, 0, 0, 0.9);
        white-space: nowrap;
        color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        font-size: 0.8rem;
        z-index: 1;
        border-radius: 1rem;
        opacity: 0;
        transition: all 200ms ease-in-out;
        display: inline-block;
      }
      .confirmation.down {
        top: unset;
        bottom: -1.5rem;
      }
      a {
        display: inline-block;
      }
      .confirmation.visible {
        opacity: 1;
        top: -2rem;
      }
      .confirmation.down.visible {
        opacity: 1;
        top: unset;
        bottom: -2.5rem;
      }
      textarea {
        position: fixed;
        top: -100px;
        height: 1px;
        width: 1px;
        overflow: hidden;
        border: none;
      }
    `}</style>
  </>

}