import { sendMessage } from "@/src/useMessage";

export default async function withLoading(callback, delay = 0) {
  let timer = 0
  try {
    if (delay) {
      timer = setTimeout(() => sendMessage('loading', true), delay)
    } else {
      sendMessage('loading', true)
    }
    await callback()
  } finally {
    if (timer) clearTimeout(timer)
    sendMessage('loading', false)
  }
}
