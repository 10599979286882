import classNames from "classnames"
import { useEffect, useRef, useState } from "react"


export default function ProgressBar({value, disabled, showLabel, color}) {

  const barRef = useRef(null)
  const [width, setWidth] = useState(0)

  color = color || '#6FBE44'

  // Grab the width of the progress bar
  useEffect(() => {
    setWidth(barRef.current.getBoundingClientRect().width)
  }, [barRef, setWidth])

  // Don't let the progress value go above 100 or below 0
  const progress = Math.min(100, Math.max(0, value))

  // Position the label to the right or left of the green bar
  let flipped  = !isNaN(progress) && (width * progress / 100) > 40
  let wrapperX = `${progress}%`
  let labelX   = flipped ? `-120%` : '20%'

  // If the progress bar is too narrow, put the label right in the center
  const tiny = width < 60
  if (tiny) {
    flipped = false
    wrapperX = '50%'
    labelX = '-50%'
  }

  const labelStyle       = { transform: `translate(${labelX},   0)` }
  const labelWrpperStyle = { transform: `translate(${wrapperX}, 0)` }

  const labelVisible = !isNaN(progress) && !!showLabel

  return <>
    <div className={classNames("progress-container", {disabled})} ref={barRef}>
      <div className="progress-bar" style={{transform: `translate(${-100 + (progress || 0)}%, 0)`}}>
      </div>
      <div className="inner-border"></div>
      { labelVisible &&
        <div className="label-container">
          <div className="label-wrapper" style={labelWrpperStyle}>
            <div className={classNames("label", {flipped})} style={labelStyle}>
              {Math.round(progress)}<span className="percent-sign">%</span>
            </div>
          </div>
        </div>
      }
    </div>
    <style jsx>{`
      .progress-container {
        border: 1px solid #ccc;
        width: 100%;
        min-width: 50px;
        overflow: hidden;
        background-color: white;
        position: relative;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
      }
      .label-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
      }
      .label-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
      }
      .label {
        font-weight: 700;
        text-align: left;
        color: #888;
        display: inline-block;
        margin-bottom: -1px;
        font-size: 0.8rem;
      }
      .percent-sign {
        font-size: 0.7rem;
      }
      .label.flipped {
        color: white;
      }
      .inner-border {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border: 3px solid white;
        display: none;
      }
      .progress-bar {
        height: 1rem;
        background: ${color};
        position: relative;
        box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
        transform: translate(-100%, 0);
      }
      .progress-bar,
      .label-wrapper,
      .label {
        /* TODO: Find out if transition speed slower than 150ms causes jitter on
        actual iOS devices, like it does with the simulator (see photo upload)*/
        transition: transform 500ms;
      }
      .progress-container.disabled .progress-bar{
        background-color: #ccc;
      }
    `}</style>
  </>

}